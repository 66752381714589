import { quest as domainQuest } from "@fscrypto/domain";
import { cn } from "@fscrypto/ui";
import { bucketsColorRanges } from "~/features/ecosystem/style-variants";

interface HSLA {
  h: number;
  s: number;
  l: number;
  a: number;
}

function parseHsla(hsla: string): HSLA {
  const match = hsla.match(/hsla?\((\d+),\s*(\d+)%?,\s*(\d+)%?,?\s*(\d*\.?\d+)?\)/);
  if (!match) {
    throw new Error("Invalid HSLA color format");
  }
  const [, h, s, l, a] = match;
  return { h: Number(h), s: Number(s), l: Number(l), a: a !== undefined ? Number(a) : 1 };
}

function interpolateHsla(color1: HSLA, color2: HSLA, factor: number): HSLA {
  const interpolate = (start: number, end: number) => start + factor * (end - start);
  const result = {
    h: interpolate(color1.h, color2.h),
    s: interpolate(color1.s, color2.s),
    l: interpolate(color1.l, color2.l),
    a: interpolate(color1.a, color2.a),
  };
  return result;
}
function hslaToString(hsla: HSLA): string {
  return `hsla(${hsla.h}, ${hsla.s}%, ${hsla.l}%, ${hsla.a})`;
}

function generateGradientColors(startHsla: string, endHsla: string, numColors: number): string[] {
  if (numColors === 1) {
    return [endHsla];
  }
  const startColor = parseHsla(startHsla);
  const endColor = parseHsla(endHsla);
  const colors = [];

  for (let i = 0; i < numColors; i++) {
    const factor = i / (numColors - 1);
    const interpolatedColor = interpolateHsla(startColor, endColor, factor);
    colors.push(hslaToString(interpolatedColor));
  }

  return colors;
}

export const QuestBucketPill = ({
  quest,
  rewardOverview,
}: {
  quest: domainQuest.Quest;
  rewardOverview?: domainQuest.QuestWithUserStateAndReward["rewardOverview"];
}) => {
  const ecoParse = domainQuest.questEcosystemSchema.safeParse(quest.project.name);
  const ecosystem = ecoParse.success ? ecoParse.data : ("primary" as domainQuest.QuestEcosystem);
  let buckets = rewardOverview?.buckets;
  if (!buckets) buckets = [{ id: "1", minScore: 0, maxScore: 25, rewardAmount: 0, slots: 0, percentageAmount: 0 }];
  const { fromColor, toColor } = bucketsColorRanges[ecosystem];
  const ecoSteppedColors = generateGradientColors(fromColor, toColor, buckets.length);
  return (
    <div className="flex w-full flex-row pb-1 pt-4">
      {buckets.map((bucket, idx) => {
        const isCurrentBucket = rewardOverview?.userBucketId === bucket.id;
        const borderRadiusClasses = cn({
          "rounded-tl-lg rounded-bl-lg": idx === 0, // Apply border-radius to the first element
          "rounded-tr-lg rounded-br-lg": idx === buckets.length - 1, // Apply border-radius to the last element
        });
        return (
          <div
            key={bucket.id}
            className={`relative flex w-full justify-center p-1 ${borderRadiusClasses} ${!rewardOverview ? "blur-[3px]" : ""}`}
            style={{ backgroundColor: ecoSteppedColors[idx] }}
          >
            <div
              key={`label-${bucket.id}`}
              className={`absolute -top-6 mx-auto w-full text-sm ${
                isCurrentBucket ? `text-contrast font-medium` : "dark:text-gray-40 text-gray-50"
              }`}
            >
              <div className="flex justify-center">{`Scores ${bucket.minScore}-${bucket.maxScore}`}</div>
            </div>
            <div className={cn("text-lg", `${isCurrentBucket ? "font-semibold" : ""}`)}>
              {bucket.rewardAmount} {rewardOverview?.currency ?? "????"}
            </div>
          </div>
        );
      })}
    </div>
  );
};
